<script>
import Validator from "../core/models/validation/candidatesQuickReserveValidator"

import FormErrors from '@/util/form-errors'
export default {
  name: 'CreateExamValidation',
  render() {
    return this.$scopedSlots.default({
      validationBeforeSend: this.validationBeforeSend,
    })
  },

  data:() => ({
    formErrors: new FormErrors()
  }),

  methods: {
    validationBeforeSend(payload) {
      new Validator(payload.data, this.formErrors).validate()
      this.$emit('validated', {...payload, validator: this.formErrors})
    },
  }
}
</script>