import { REQUIRED } from "@/util/validation-rules"
import { isDateFuture } from "@/util"
import { DATE_DEFAULT_REGXP } from "@/util/const"
import CMExamConfigValidator from "@/app/admin/modules/course_managment/classes/CMExamConfigValidator"

export default class CandidatesQucikReserveValidator extends  CMExamConfigValidator{
  constructor(data, validator) {
    super(data, validator)
  }

  isValid() {
    this.validateRequired('city', this.data.city)
    this.validateDate()
    this.validateLocation()
    this.validateTimeTo()
    this.validateTimeFrom()
    
    return this.validator.isValid()
  }
}